.calendario {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  color: #001a4c;
  padding-top: 24px;
  @media screen and (max-width: 350px) {
    width: 280px;
  }
}

.month-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  padding: 16px 0;
  @media screen and (max-width: 350px) {
    width: 280px;
  }

  .month {
    // color: #000;
    color: #001a4c;
    font-size: 20px;
    // font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    flex: 3;
  }

  .months-change {
    display: flex;
    align-items: center;

    .current-month {
      cursor: pointer;
    }
  }
}

.heading-calendario-aste {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 350px;
  @media screen and (max-width: 350px) {
    width: 280px;
  }
  .day {
    text-align: center;
    padding-top: 12px;
    // color: #000;
    color: #001a4c;
    text-transform: uppercase;
    font-size: 1.2rem;
    // background-color: aqua;
  }
}

.blocks {
  display: grid;
  width: 350px;
  grid-template-columns: repeat(7, 1fr);
  padding-bottom: 16px;
  border-bottom: 1px solid #d2d2d2;
  @media screen and (max-width: 350px) {
    width: 280px;
  }
  .block {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 350px) {
      height: 40px;
    }
    // text-align: right;
    // padding: 1rem;
    // border: 1px solid #ddd;
    font-size: 1.2rem;
    &.disabled {
      color: rgb(181, 181, 181);
      cursor: not-allowed;
      pointer-events: none;
    }
    &.active {
      background-color: #51f8a8;
      border-radius: 50%;
    }
  }
}

.calendar-select {
  font-family: "Poppins", sans-serif;
  flex: 9;
}
.calendar-input-container {
  align-self: flex-start;
  width: 350px;
  padding: 16px 0;
  @media screen and (max-width: 350px) {
    width: 280px;
  }
}
