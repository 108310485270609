// FONT
@import "./font/font.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: "Poppins", sans-serif;
}

// input[type="text"],
// input[type="button"] {
//   -webkit-appearance: none;
//   -webkit-border-radius: 0;
//   border-radius: 0;
//   appearance: none;
// }

.title-style {
  font-weight: 600;
  font-size: 24px;
  padding-left: 32px;
  color: #001a4c;
  @media screen and (max-width: 576px) {
    font-size: 20px;
    padding-left: 0;
  }
}
.link-style {
  text-decoration: none;
  color: inherit;
  width: 350px;
  &.disabled {
    pointer-events: none;
  }
}
.checkbox-style {
  flex: 1;
  transform: scale(2);
  @media screen and (max-width: 576px) {
    transform: scale(1.2);
  }
}
.checkbox-label {
  text-transform: uppercase;
  flex: 20;
  margin-left: 8px;
  @media screen and (max-width: 576px) {
    flex: 10;
    margin-left: 0px;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &.title-container {
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.main-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}
.title-container {
  padding: 24px 5% 24px;
  border-bottom: 1px solid #e2e2e2;
  @media screen and (max-width: 768px) {
    border-bottom: none;
    padding: 24px 5% 0;
  }
  &.hide-title {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
.desktop-btn,
.only-desktop {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.input-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.right-container {
  position: relative;
  margin-left: 15%;
  border-top: 1px solid #e2e2e2;
  width: 85%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f3f5f4;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @media screen and (max-width: 1200px) {
    width: 75%;
    margin-left: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 100vh;
    margin-left: 0%;
  }
}
.right-container-noscroll {
  position: relative;
  margin-left: 15%;
  border-top: 1px solid #e2e2e2;
  width: 85%;
  display: flex;
  flex-direction: column;
  background-color: #f3f5f4;

  @media screen and (max-width: 1200px) {
    width: 75%;
    margin-left: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0%;
  }
}

select:disabled {
  opacity: 0.5 !important;
}
