.mobile-footer {
  display: none;
  justify-content: center;
  align-self: center;
  height: 80px;
  // background-color: #000;
  background-color: #001a4c;
  color: #fff;
  width: 100%;
  padding: 16px 5%;
  display: none;
  svg {
    stroke: #fff;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
}
