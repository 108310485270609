.flag-container {
  // position: absolute;
  // top: 5%;
  // right: 5%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 576px) {
    // top: 16px;
    // right: 8px;
  }
}

.flag {
  color: #001a4c40;
  cursor: pointer;
  min-width: 30px;
  padding: 0 12px;
  &:first-of-type {
    border-right: #001a4c solid 1px;
  }
  &.active {
    color: #001a4c;
    font-weight: 600;
  }
}
