.responsive-section {
  width: 50%;
  color: #001a4c;
  @media screen and (max-width: 576px) {
    width: 100%;
    padding-bottom: 24px;
  }
}

.right-section {
  display: flex;
  justify-content: flex-end;
  color: #001a4c;
  @media screen and (max-width: 576px) {
    justify-content: flex-start;
  }
}
